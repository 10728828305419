.province-detail-layer {
    left: 0;
    right: 0;
    user-select: none;
    bottom: 45px;
    position: fixed;
    animation: fade-y-in 0.3s both;
    background: linear-gradient(rgba(0,0,0,0.8) 30%, rgba(0,0,0,0.35) 100%);
    z-index: 20;
    overflow-y: auto;
    min-height: 70vh;
    max-height: 95vh;

}
.province-detail-layer.closing {
    animation: fade-y-out 0.3s both;
}
.province-detail-container {
    margin-right: 10px;
    color: #fff;
}
.province-detail-title {
    margin: 10px 0 0 10px;
}
.province-detail-container .boxs {
    display: flex;
    flex-wrap: wrap;
}
.province-detail-container .normal-box {
    margin-left: 10px;
    margin-top: 5px;
    padding: 3px;
    color: #fff;
    position: relative;
    width: calc(25vw - 12.5px);
    max-width: 120px;
    height: 70px;
    background: #666666;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 5px;
}
.province-detail-container .normal-box.unlocked {
    background: dodgerblue;
}
.province-detail-container .normal-box.unlocked::after {
    content: ' ';
    background: url("../../assets/step.png");
    position: absolute;
    right: -6px;
    bottom: 0;
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    background-position: center center;
    opacity: 0.85;
}
.province-detail-container .normal-box .medium-title {
    font-size: 14px;
    line-height: 17px;
}
.province-detail-container .normal-box .small-text {
    font-size: 10px;
    line-height: 12px;
}
.province-detail-container .normal-box .very-small-text {
    font-size: 10px;
    line-height: 12px;
}

.province-detail-container .normal-box .rank-right {
    font-size: 90px;
    color: rgba(255,255,255,0.3);
    position: absolute;
    right: 4px;
    top: -6px;
    line-height: 90px;
    letter-spacing: -10px;
}
.province-detail-container .normal-box .rank-right.basic-info {
    font-size: 68px;
    line-height: 68px;
    color: rgba(255,255,255,0.7);
    letter-spacing: -4px;
}
.province-detail-container .normal-box .rank-right.smaller {
    font-size: 68px;
    line-height: 68px;
}
.province-detail-container .normal-box .rank-right-bottom {
    top: auto;
    bottom: -10px;
}

.province-detail-layer .province-detail-layer-close-btn {
    margin-top: 10px;
    margin-left: 10px;
    height: 25px;
    line-height: 25px;
    width: 120px;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 12.5px;
    right: 20px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.province-detail-layer .province-detail-layer-close-btn:hover {
    background: #fff;
    color: #666666;
}

.airport-bg-disabled {
    width: 100%;
    height: 75px;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /*filter: grayscale(100%);*/
}
.airport-bg-disabled::after {
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    background: rgba(0,0,0,0.7);
}